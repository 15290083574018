<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <!--
    v-html it's globally disallowed, so for cases where we have no
    choise (eg: the BE returning raw html from Froala).
    We use only here as the raw html props it's passed through
    a sanitized as a layer of protection
  -->
  <div v-bind="$attrs" v-html="tailwindBasedSafeHtml" />
</template>

<script setup lang="ts">
import sanitizeHtml from 'sanitize-html'
import { computed } from 'vue'

const allowedTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'abbr',
  'acronym',
  'b',
  'blockquote',
  'br',
  'code',
  'div',
  'em',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'table',
  'td',
  'tr',
  'ul',
  'img',
]

const allowedAttributes = {
  '*': [
    'align',
    'alink',
    'alt',
    'bgcolor',
    'border',
    'cellpadding',
    'cellspacing',
    'class',
    'color',
    'cols',
    'colspan',
    'coords',
    'dir',
    'face',
    'height',
    'href',
    'hspace',
    'ismap',
    'lang',
    'marginheight',
    'marginwidth',
    'multiple',
    'nohref',
    'noresize',
    'noshade',
    'nowrap',
    'ref',
    'rel',
    'rev',
    'rows',
    'rowspan',
    'scrolling',
    'shape',
    'span',
    'summary',
    'tabindex',
    'title',
    'usemap',
    'valign',
    'value',
    'vlink',
    'vspace',
    'width',
  ],
  a: ['href', 'alt', 'title', 'rel'],
  img: ['src'],
}

const props = withDefaults(defineProps<{
  rawHtml: string,
  tag?: string,
}>(), { tag: 'div' })

const { convertToTailwindClasses } = useRichTextToTailwind()

const safeHtml = computed(() =>
  sanitizeHtml(props.rawHtml, {
    allowedTags,
    allowedAttributes,
  }),
)

const tailwindBasedSafeHtml = computed(() =>
  convertToTailwindClasses(safeHtml.value),
)
</script>
