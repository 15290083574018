export const useRichTextToTailwind = () => {
  const replacements = {
    '<strong>': '<span class="font-bold">',
    '</strong>': '</span>',
    '<a>': '<a class="font-bold text-primary hover:text-primary/90">',
    '</a>': '</a>',
    '<b>': '<span class="font-bold">',
    '</b>': '</span>',
    '<em>': '<span class="italic">',
    '</em>': '</span>',
    '<i>': '<span class="italic">',
    '</i>': '</span>',
    '<u>': '<span class="underline">',
    '</u>': '</span>',
    '<s>': '<span class="line-through">',
    '</s>': '</span>',
    '<h1>': '<h1 class="pb-4 text-4xl font-bold">',
    '</h1>': '</h1>',
    '<h2>': '<h2 class="pt-8 pb-4 text-3xl font-bold">',
    '</h2>': '</h2>',
    '<h3>': '<h3 class="pt-8 pb-3 text-2xl font-bold">',
    '</h3>': '</h3>',
    '<h4>': '<h4 class="pt-8 pb-3 text-xl font-bold">',
    '</h4>': '</h4>',
    '<h5>': '<h5 class="pt-6 pb-2 text-lg font-bold">',
    '</h5>': '</h5>',
    '<h6>': '<h6 class="pt-4 pb-2 text-base font-bold">',
    '</h6>': '</h6>',
    '<ul>': '<ul class="list-disc list-inside">',
    '</ul>': '</ul>',
    '<ol>': '<ol class="list-decimal list-inside">',
    '</ol>': '</ol>',
    '<blockquote>': '<blockquote class="border-l-4 pl-4 italic">',
    '</blockquote>': '</blockquote>',
    '<code>': '<code class="bg-slate-200 p-1 rounded">',
    '</code>': '</code>',
  }

  /**
   * Converts standard HTML tags to Tailwind CSS classes.
   * @param {string} html - The HTML string to convert.
   * @returns {string} - The HTML string with Tailwind CSS classes.
   */
  const convertToTailwindClasses = (html: string): string => {
    // Replace HTML entities
    const decodedHtml = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

    // Replace rich-text entities with Tailwind styled classes
    let convertedHtml = Object.entries(replacements).reduce(
      (acc, [key, value]) => acc.replace(new RegExp(key, 'g'), value),
      decodedHtml,
    )

    // Replace <a> tags separately to account for attributes
    convertedHtml = convertedHtml.replace(
      /<a\b(?![^>]*\bclass\b)[^>]*>/gi,
      (match) => match.replace('<a', '<a class="font-bold text-accent hover:text-accent/50" visited:text-primary'),
    )

    return convertedHtml
  }

  return {
    convertToTailwindClasses,
  }
}
